<template>
  <div>
    <div v-if="list.physical.length > 0" class="content-reward-list pt-2">
      <div
        class="m-2 content-reward-group"
        v-for="(item, index) in list.physical"
        :key="index"
      >
        <div
          :class="[
            'm-2',
            list.out_of_stock
              ? 'content-privilege-group-fade'
              : 'content-privilege-group'
          ]"
        >
          <b-row class="line-top">
            <b-col cols="5">
              <div>
                <b-img-lazy
                  v-if="item.image_url"
                  v-bind="mainProps"
                  :src="item.image_url"
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy>
                <b-img-lazy
                  v-else
                  v-bind="mainProps"
                  :src="require('@/assets/images/default-image.png')"
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy>
              </div>
            </b-col>
            <b-col>
              <div class="title line-clamp-2">{{ item.product_name }}</div>
              <div class="description line-clamp-2">
                {{ getDescription(item.description) }}
              </div>
              <div class="point mt-1">
                <span class="fa-stack fa-1x icon-background">
                  <font-awesome-icon icon="star" class="point-icon-color" />
                </span>
                {{ item.point_used }} คะแนน
              </div>
            </b-col>
          </b-row>
          <b-row class="px-2">
            <b-col cols="8" class="py-2">
              <div v-if="list.out_of_stock" class="out-of-stock">
                ของรางวัลหมด
              </div>
              <div class="until-date" v-else>
                วันหมดอายุ: {{ dateFormat(item.valid_to) }}
              </div>
            </b-col>
            <b-col cols="4" class="p-0 text-right">
              <b-button
                class="btn-redeem"
                @click.prevent="insertRedeem(item.id)"
              >
                <div class="insert-redeem w-100">ดูรายละเอียด</div>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="notfound-data">ไม่พบข้อมูลของรางวัล</div>
    </div>
    <!-- <div v-if="list.privilege">
            <div
                class="m-2 content-reward-group"
                v-for="(p,i) in list.privilege"
                :key="i"
            >
                <div :class="['line-content']">
                    <b-row class="line-top">
                        <b-col cols="5">
                            <div>
                                <b-img-lazy
                                    v-if="p.image_url"
                                    v-bind="mainProps"
                                    :src="p.image_url"
                                    class="lazy-image"
                                    alt="Image 1"
                                ></b-img-lazy>
                                <b-img-lazy
                                    v-else
                                    v-bind="mainProps"
                                    :src="require('@/assets/images/default-image.png')"
                                    class="lazy-image"
                                    alt="Image 1"
                                ></b-img-lazy>
                            </div>
                        </b-col>
                        <b-col>
                            <div class="title line-clamp-2">{{p.name}}</div>
                            <div class="description line-clamp-2">{{p.short_description}}</div>
                        </b-col>
                    </b-row>
                    <b-row class="px-2">
                        <b-col cols="8" class="py-2">
                            <div class="until-date">วันหมดอายุ: {{dateFormat(p.valid_to)}}</div>
                        </b-col>
                        <b-col cols="4" class="p-0 text-right">
                            <b-button class="btn-redeem" @click.prevent="insertRedeemPrivilege(p)">
                                <div class="insert-redeem">ดูรายละเอียด</div>
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div> -->
    <!-- <div v-else>
            <div class="notfound-data">ไม่พบข้อมูลของรางวัล</div>
        </div> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    point: {
      required: true,
      type: Number
    },
    list: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 600,
        class: ""
      }
    };
  },
  methods: {
    insertRedeem(id) {
      this.$router.push(`/reward/${id}`);
    },
    insertRedeemPrivilege(data) {
      // this.$router.push(`/reward/privilege/${id}`);
    },
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    },
    getDescription(item) {
      return item?.replace(/(<([^>]+)>)/gi, "");
    }
  }
};
</script>

<style lang="scss" scoped>
.content-reward-list {
  background-color: #fff;
  margin: 1rem;
  padding: 10px 0;
}
.total-point {
  border-bottom: 1px solid gray;
}
.totalpoint-font {
  font-weight: 600;
  font-size: 18px;
}
.lazy-image {
  font-size: 1px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.content-reward-group {
  border-radius: 5px;
  background-color: #f0f0f0;
}
.title {
  font-size: 16px;
  font-weight: 600;
}
.description {
  font-size: 13px;
  color: #4b4b4b;
}
.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.insert-redeem {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
}
.until-date {
  font-size: 13px;
}
.status-false {
  opacity: 0.6;
}
.btn-redeem {
  background-color: transparent;
  color: #000;
  border: none;
}
.line-top {
  border-bottom: 1px dashed #aaaaaa;
  margin: 0 0;
  padding: 5px 0;
  // border-bottom: 1px dashed rgb(170, 170, 170);
}
.point {
  font-size: 14px;
  font-weight: 600;
  // color: green;
}
.notfound-data {
  color: #fff;
  text-align: center;
  padding: 50px 0;
}
.point-icon-color {
  color: #fff;
  padding-left: 4px;
}
.icon-background {
  background-color: #bb2e2e;
  height: 20px;
  width: 20px;
  border-radius: 50px;
}
.content-privilege-group {
  background-color: #f0f0f0;
}
.content-privilege-group-fade {
  background-color: #f0f0f0;
  opacity: 0.5;
}
.out-of-stock {
  color: red;
  font-size: 14px;
  font-weight: 600;
}
</style>
