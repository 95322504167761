<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
   
      <b-tabs v-model="tabIndex">
        <b-tab title="ทั้งหมด">
          <RewardListComponent :point="totalPoint" :list="rewardsList" />
        </b-tab>
        <b-tab title="ประวัติการแลก">
          <RewardHistory :list="rewardsHistory" :totalCount="totalCount" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";
import RewardListComponent from "@/components/reward/RewardListComponent.vue";
import RewardHistory from "@/components/reward/history/RewardHistory";
export default {
  components: {
    Breadcrumb,
    RewardListComponent,
    OtherLoading,
    RewardHistory
  },
  data() {
    return {
      totalPoint: 0,
      breadcrumb: [
        {
          text: "ของรางวัล",
          to: "/profile"
        }
      ],
      rewardsList: {},
      isLoading: true,
      rewardsHistory: {},
      filter: {
        page: 1,
        take: 10
      },
      totalCount: 0,
      tabIndex: 0
    };
  },
  created: async function () {
    if (this.$route.query) {
      if (parseInt(this.$route.query.tabIndex) === 1) {
        this.tabIndex = 1;
      }
    }
    // await this.getUserProfile();
    await this.getRewardList();
    await this.getRewardHistory();
  },
  methods: {
    // getUserProfile: async function () {
    //   await this.$store.dispatch("getUserApi");
    //   this.totalPoint = this.$store.state.shortProfile.total_point;
    // },
    async getRewardList() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Reward/reward_list`)
        .then(
          async data => {
            this.isLoading = false;
            if (data.result == 1) {
              this.rewardsList = data.detail;
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    },
    async getRewardHistory() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Reward/reward_history`)
        .then(
          async data => {
            if (data.result == 1) {
              this.rewardsHistory = data.detail;
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-weight: 600;
  border-bottom: 4px solid #fff !important;
  background-color: #000;
  color: #fff;
  border-top: none;
  border-right: none;
  border-left: none;
}
::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}
::v-deep .nav-tabs {
  background-color: #000;
  border-bottom: none;
}
::v-deep a {
  color: #fff;
}
.title {
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 80px;
}
.icon-history-custom {
  color: #fff;
  text-align: right;
  margin-top: -31px;
  margin-bottom: 5px;
  right: 15px;
}
// .content-page{
//   margin-top: 80px;
// //   min-height: 100vh;
// }
</style>
